export const STATES = [
    "PENDIENTE",
    "RECHAZADO",
    "AUTORIZADO",
    "EN PROCESO",
    "ENCARGADO",
    "POSTERGADO",
    "ANULADO",
    "ENTREGADO",
    "ENTREGADO PARCIAL",
    "DISPONIBLE",
    "DISPONIBLE PARCIAL"
];

export const ifState = (state) => {
    switch (state) {
        case "PENDIENTE":
            return ["RECHAZADO", "AUTORIZADO"];
        case "RECHAZADO":
            return [];
        case "AUTORIZADO":
            return ["EN PROCESO", "DISPONIBLE PARCIAL", "DISPONIBLE"]
        case "EN PROCESO":
            return ["ANULADO", "POSTERGADO","ENCARGADO", "DISPONIBLE PARCIAL", "DISPONIBLE"];
        case "ENCARGADO":
            return ["ANULADO", "POSTERGADO", "DISPONIBLE PARCIAL", "DISPONIBLE"];
        case "ANULADO":
            return [];
        case "POSTERGADO":
            return ["EN PROCESO"];
        case "DISPONIBLE PARCIAL":
            return ["DISPONIBLE", "ENTREGADO PARCIAL", "ENTREGADO",];
        case "DISPONIBLE":
            return ["ENTREGADO PARCIAL", "ENTREGADO",];
        case "ENTREGADO PARCIAL":
            return ["ENTREGADO"];
        case "ENTREGADO":
            return [];

        default: return [];

    }
}

export const ifStateBack = (state) => {
    switch (state) {
        case "PENDIENTE":
            return [];
        case "RECHAZADO":
            return [];
        case "AUTORIZADO":
            return []
        case "EN PROCESO":
            return ["AUTORIZADO", "DISPONIBLE PARCIAL", "DISPONIBLE"];
        case "ENCARGADO":
            return ["EN PROCESO", "AUTORIZADO", "ANULADO", "POSTERGADO", "DISPONIBLE PARCIAL", "DISPONIBLE"];
        case "ANULADO":
            return ["ENCARGADO","EN PROCESO", "AUTORIZADO", "POSTERGADO","ENCARGADO", "DISPONIBLE PARCIAL", "DISPONIBLE"];
        case "POSTERGADO":
            return ["ANULADO","ENCARGADO","EN PROCESO", "AUTORIZADO", "ANULADO","ENCARGADO", "DISPONIBLE PARCIAL", "DISPONIBLE"];
        case "DISPONIBLE PARCIAL":
            return ["POSTERGADO","ANULADO","ENCARGADO","EN PROCESO", "AUTORIZADO","ANULADO", "POSTERGADO","ENCARGADO", "DISPONIBLE"];
        case "DISPONIBLE":
            return ["DISPONIBLE PARCIAL", "POSTERGADO","ANULADO","ENCARGADO","EN PROCESO", "AUTORIZADO", "ANULADO", "POSTERGADO","ENCARGADO", "DISPONIBLE PARCIAL"];
        case "ENTREGADO PARCIAL":
            return ["DISPONIBLE","DISPONIBLE PARCIAL", "POSTERGADO","ANULADO","ENCARGADO","EN PROCESO", "AUTORIZADO", "ENTREGADO"];
        case "ENTREGADO":
            return ["ENTREGADO PARCIAL","DISPONIBLE","DISPONIBLE PARCIAL", "POSTERGADO","ANULADO","ENCARGADO","EN PROCESO", "AUTORIZADO","ENTREGADO PARCIAL"];

        default: return [];

    }
}