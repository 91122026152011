//Componentes Generales
import React, { useContext, useEffect } from "react";
import productsContext from "../../context/productos/productsContext";
import agregarReqContext from "../../context/agregarReq/agregarReqContext";
import authContext from "../../context/authentication/authContex";
import FormReq from "../../components/FormReq/FormReq";

// Alertas
import Swal from 'sweetalert2'

//Estilos
import "./AddReq.css"

/* 
    Vista donde se gestiona toda la lógica para añadir un requerimiento.
        Donde reqEdit no esta en uso.
              cerrar no esta en uso.
*/
function AddReq({ reqEdit, cerrar }) {

    //Context de Autenticacion   
    const aContext = useContext(authContext);
    const { usuario: user } = aContext;

    //Context productos
    const pContext = useContext(productsContext);
    const { products, actualizarProducts } = pContext;
    
    //Context agregar requerimiento
    const addContext = useContext(agregarReqContext);
    const { req, guardarRequerimiento,itemsList } = addContext;

    /* 
        Disparador que actualiza la lista de productos disponibles al momento de renderizar el componente.
    */
    useEffect(() => {
        const fn = async () => {
            await actualizarProducts();
        }
        fn();
    }, []);

    /* 
        Función encargada de validar los campos de un requerimiento a guardar.
            Donde r es el requerimiento.
                  i son los items del requerimiento.
        Retorna una respuesta.
    */
    const validacionesCampos = (r, i) => {
        let resp = '';
        if(r.title === ""){
            resp = "Falta ingresar titulo de requerimiento";
        }else{
            if(i.length === 0){
                resp = "Ingrese por lo menos un item"
            }else{  
                let control = 0;      
                for (const element of i) {
                    if(element.quantity === 0){
                        control = 1
                    } 
                } 
                if(control === 1){
                    resp = "Existe un item sin cantidad asignada";
                }
            }
        }
        return resp;
    }

    /* 
        Función encargada de guardar el requerimiento.
            Donde requeriment es el requerimiento a guardar.
                  items son los items del requerimiento.
                  files son los arhivos adjuntos.
    */
    const handleSaveReq = async (requeriment, items, files) =>{
        
        const msj = validacionesCampos(requeriment, items);

        if (requeriment !== undefined && requeriment !== null) {
            if (msj === "") {
                const resp = await guardarRequerimiento(requeriment, items,user, files);
                Swal.fire({
                    title: `Se guardo un nuevo requerimiento`,
                    icon: 'success',
                    text: `El numero de requerimiento es : ${resp.tDocNroFor}`,
                    showConfirmButton: true,
                    allowEnterKey: true
                });
                return true;
            }else{
                Swal.fire({
                    title: msj,
                    icon: 'warning',
                    showConfirmButton: true,
                    allowEnterKey: true
                });
                return false;
            }
        }else{
            return false;
        }
    }

    return (
        <div>
            <FormReq req={reqEdit ? reqEdit : req} itemList={itemsList} productList={products} guardarReq={handleSaveReq} cerrar={cerrar} />
        </div>
    );
}

export default AddReq