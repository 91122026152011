//Componentes generales
import React, { useContext, useReducer } from "react";
import authReducer from "./authReducer";
import authContext from "./authContex";

//Axios
import clienteAxios from "../../config/axios";

//Rutas de api
import { getUser, authUser } from "../../api/user";
import { verifyLicense } from '../../api/license';

//Acciones
import { 
    REGISTRO_EXITOSO,
    REGISTRO_ERROR,
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
    LIMPIAR_ERROR,
    MENSAJE_LICENCIA
} from "./authActions";



/* 
    State encargado de la gestión de autorización de usuario y licencias.
*/
const AuthState = props => {

   
    //Valores iniciales
    const initialState = {
        token: null,
        autenticado: false,
        usuario: null,
        mensaje: null,
        cargando: true,
        mensajeLicencia: ''
    };

    const [state, dispatch] = useReducer(authReducer, initialState);

    /* 
        Función encargada de registrar un usuario.
    */
    const registrarUsuario = async datos => {

        try{
            const respuesta = await clienteAxios.post('/usuarios', datos);

            dispatch({
                type: REGISTRO_EXITOSO,
                payload: respuesta.data.data
            });

            //Obtener el usuario
            usuarioAutenticado();

        } catch (error) {
            
            const alerta = {
                msg: error.response.data.msg ,
                categoria: 'alerta-error'
            };

            dispatch({
                type:REGISTRO_ERROR,
                payload: alerta
            });
        }
    }

     /* 
        Función que obtiene el usuario en sesión mediante el token asignado al momento de iniciar sesión.
     */
    const usuarioAutenticado = async () => {

        const token = localStorage.getItem('token');
        //T0D0: Funcion para enviar el token por headers
        //tokenAuth(token);
        
        try{
            const respuesta = await getUser(token); //clienteAxios.get('/user/auth');
            dispatch({
                type: OBTENER_USUARIO,
                payload: respuesta.data.data
            });

        } catch (err) {
            if(err.message === "Network Error"){
                dispatch({
                    type: LOGIN_ERROR,
                    payload: {
                        msg: 'Servidor fuera de Servicio. Compruebe conexion o licencia'
                    }
                })
            }
        }
    } 

    /* 
        Función que inicia la sesión del usuario despues de superar las validaciones.
        Asigna un token local unico a la sesión.
    */
    const iniciarSesion = async datos => {
        if(datos.userName.trim() !== '' || datos.password.trim() !== '') {
            
            try{
                const respuesta = await authUser(datos); //clienteAxios.post('/user/auth', datos);
                console.log(respuesta.data.data);
                dispatch({
                    type: LOGIN_EXITOSO,
                    payload: respuesta.data.data
                })
                
                localStorage.setItem('token', respuesta.data.data);
                return true;

            } catch (error){

                const alerta = {
                    msg: error.response.data.errMsg ,
                    categoria: 'alerta-error'
                };

                dispatch({
                    type:LOGIN_ERROR,
                    payload: alerta
                });

                //throw error;
                return false;
            }

        }else{
            const alerta = {
                msg: "Debe Ingresar un Usuario valido con su Clave" ,
                categoria: 'alerta-error'
            };

            dispatch({
                type:LOGIN_ERROR,
                payload: alerta
            });
        }
    }

    /* 
        Función encargada de cerrar la sesión del usuario en sesión.
        Elimina el token local de la sesión.
    */
    const cerrarSesion = async () => {
        await localStorage.removeItem('token');
        dispatch({
            type: CERRAR_SESION,
            payload: 'Se cerro sesion'
            
        })
    }

    /* 
        Función que limpia el valor de error.
    */
    const limpiarError = () => {
        dispatch({
            type: LIMPIAR_ERROR
        })
    }

    /* 
        Función que verficia la licencia del cliente.
        No esta en uso.
    */    
    const verificarLicencia = async () => {
        const respuesta = await verifyLicense();//clienteAxios.get('/license/verify');
        if(respuesta.data.data.dias <= 10){
            /*dispatch({
                type: MENSAJE_LICENCIA,
                payload: `LICENCIA DE USO VENCE ${moment(respuesta.data.fechaExpiracion).format('DD/MM/YYYY ')}.  Comuníquese con el Administrador.`
            })*/
        }
        return 20;
    }

    return(
        <authContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                mensaje: state.mensaje,
                cargando: state.cargando,
                mensajeLicencia: state.mensajeLicencia,
                registrarUsuario,
                iniciarSesion,
                usuarioAutenticado,
                cerrarSesion,
                limpiarError,
                verificarLicencia
            }}
        >

            {props.children}

        </authContext.Provider>
    )
    
}

export default AuthState;