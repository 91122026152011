//Componentes generales
import { useEffect, useContext } from 'react';
import ReqList from '../../components/ReqList/ReqList';
import { STATES } from "../../utils/states";

//Permisos
import {VER_TODOS_REQUERIMIENTOS} from "../../utils/permisos";

//Estilos
import './Home.css';

//Contexto
import controlGLBContext from '../../context/controlGLB/controlGLBContext';
import controlContext from '../../context/control/controlContext';
import authContext from '../../context/authentication/authContex';
import usuariosContext from '../../context/usuarios/usuariosContext';
import agregarReqContext from '../../context/agregarReq/agregarReqContext';
import productsContext from '../../context/productos/productsContext';

import clienteAxios from '../../config/axios';

// Alertas
import Swal from 'sweetalert2'
import { useState } from 'react';

/* 
    Vista donde se gestiona toda la lógica de listado de requerimientos. Es la vista principal de toda la aplicación.
        Donde props no se encuentra en uso.
*/
function Home(props) {

    //Context auth
    const aContext = useContext(authContext);
    const { usuario } = aContext;

    //Context usuarios
    const uContext = useContext(usuariosContext);
    const { userListView, actualizarUserListView, userVisualiza } = uContext;

    //Context control requerimientos
    const cContext = useContext(controlContext);
    const {
        listTDoc,
        filtrosRango, 
        filtrosEstado,
        modificarFiltrosEstado,
        modificarFiltrosRango,
        iniciarFiltros,
        getProductsByNroFor,
        filtrar
    } = cContext;

    //Contexto general generación de informe
    const gContext = useContext(controlGLBContext);
    const { generarInformeReqPDF, modificarEstado, getFilesByTDoc, enviarReqApi } = gContext;


    //Context productos
    const pContext = useContext(productsContext);
    const { products, actualizarProducts } = pContext;

    //Context Edit Requerimiento
    const addContext = useContext(agregarReqContext);
    const { guardarRequerimiento, editarRequerimientoBE } = addContext;

    const handlerIniciarlizarFiltros = async () => {
        const state = STATES;
        await iniciarFiltros(state, usuario);
    }

    /* 
        Disparador que actualiza la lista de usuarios disponibles e inicializa los filtros al momento de renderizar el componente.
    */
    useEffect(() => {
        if(usuario) actualizarUserListView(usuario);
        
        handlerIniciarlizarFiltros();
    }, []);

    /* 
        Disparador que actualiza la lista de productos disponibles al momento de renderizar el componente.
    */ 
    useEffect(() => {
        const fn = async () => {
            await actualizarProducts();
        }
        fn();
    }, []);

    /* 
        Manejador que comprueba los permisos que posee el usuario en sesión para un rol enviado por parametro.
            Donde roles_autorizados es el rol a comprobar.
        Retorna true si esta autorizado, false si no.
    */
    const handleCheckPermisos = (roles_autorizados) =>{
        let autoriza = false;
        for (const element of roles_autorizados) {
            if (usuario.userType.some(item => item.typeName === element)){
                autoriza = true;
            }
        }
        return autoriza;
    }

    /* 
        Manejador para la modificación rapida de un requerimiento. Se utiliza en 3 ocaciones, edición del estado de autorización, notas privadas y notas publicas.
            Donde reqe es el requerimiento a actualizar.
                  upd es lo modificado.
    */
    const handleModifyStateReq = async (reqe, upd) =>{
        const resp = await modificarEstado(reqe, usuario, upd);
        return resp;
    }


    /* 
        Manejador que recupera todos los items de un requerimiento dado por parametro.
            Donde tDocCodFor es el Codfor del requerimiento (normalmente REQ).
                  tDocNrofor es el Nrofor del requerimiento.
    */
    const handlerVerReqDetails = async (tDocCodFor, tDocNroFor) => {
        return await getProductsByNroFor(tDocCodFor, tDocNroFor);
    }

    /* 
        Manejador que recupera los archivos adjuntados a un requerimiento especificado por parametro.
            Donde req es el requerimiento de donde recuperar los archivos.
    */
    const handlerRecuperaFiles = async (req) => {
        const files = await getFilesByTDoc(req);
        return (files) ? files.map((f) => {return {name: f.fileName === undefined ? f.name : f.fileName, url: f.url}}) : []
    }

    /* 
        Manejador encargado de generar un archivo pdf con formato brindado en el back-end. Se descarga automaticamente y se abre en una nueva pestaña.
            Donde doc es el archivo a generar.
                  user es el usuario en sesión que llama a la función.
    */
    const handlerGeneraPDF = async (doc, user) =>{
        await generarInformeReqPDF(doc, user);
    }

    /* 
        Función encargada de aplicar y/o inicializar los filtros.
            Donde filtrosProp son los filtros a aplicar.
                  signal no se encuentra actualmente en uso.
        El parametro recibido es un objeto que contiene los filtros de estado y de rango, se aplican cada uno con su respectiva función.
    */
    const apFiltros = async (filtrosProp) => {
        modificarFiltrosEstado(filtrosProp.filterEstado);
        modificarFiltrosRango(usuario._id, filtrosProp.filterRango);
    }

    /* 
        Función encargada de validar los campos del requerimiento a guardar. Valida que este ingresado el titulo, que no existan items sin cantidad y que por lo menos contenga un item con cantidad.
            Donde r es el requerimiento a validar.
                  i son los items del requerimiento a validar.
        Se llama al momento de guardar.
    */
    const validacionesCampos = (r, i) => {
        let resp = '';
        if(r.title === ""){
            resp = "Falta ingresar titulo de requerimiento";
        }else{
            if(i.length === 0){
                resp = "Ingrese por lo menos un item"
            }else{  
                let control = 0;      
                for (const element of i) {
                    if(element.quantity === 0){
                        control = 1
                    } 
                } 
                if(control === 1){
                    resp = "Existe un item sin cantidad asignada";
                }
            }
        }
        return resp;
    }

    /* 
        Manejador encargado de guardar un nuevo requerimiento. Realiza validaciones y si no la supera no se guarda el requerimiento.    
            Donde requeriment es el requerimiento a guardar.
                  items son los items del requerimiento.
                  files son los archivos adjuntos del requerimiento.
    */
    const handleSaveReq = async (requeriment, items, files) =>{
        
        const msj = validacionesCampos(requeriment, items);

        if (requeriment !== undefined && requeriment !== null) {
            if (msj === "") {
                const resp = await guardarRequerimiento(requeriment, items,usuario, files);
                Swal.fire({
                    title: `Se guardo un nuevo requerimiento`,
                    icon: 'success',
                    text: `El numero de requerimiento es : ${resp.tDocNroFor}`,
                    showConfirmButton: true,
                    allowEnterKey: true
                });
            }else{
                Swal.fire({
                    title: msj,
                    icon: 'warning',
                    showConfirmButton: true,
                    allowEnterKey: true
                });
            }
        }
    }

    /* 
        Manejador que controla el evento ante un click en el boton editar, recuperando los items y archivos adjuntos del requerimiento para comenzar la edición.
            Donde req es el requerimiento a editar. 
    */
    const handdleEditClick = async (req) =>{
            try {
                const items = await getProductsByNroFor(req.tDocCodFor, req.tDocNroFor);
                const files = (await getFilesByTDoc(req)).map((f) => {return {name: f.fileName === undefined ? f.name : f.fileName, url: f.url}});
                    return {items, files};
            } catch (error) {
                console.log("error");
                    return null;
            }
        
    }

    /* 
        Manejador encargado de guardar los cambios realizados en la edición de un requerimiento. Realiza validaciones y si no la supera no se guarda las modificaciones del requerimiento.  
            Donde requeriment es el requerimiento a guardar.
                  items son los items del requerimiento.
                  files son los archivos adjuntos del requerimiento.
                  fileBase son los archivos originales antes de la edición.
    */
    const handdleEdit = async (requeriment, items, files, fileBase)=>{
        const msj = validacionesCampos(requeriment, items);

        if (requeriment !== undefined && requeriment !== null) {
            if (msj === "") {
                const resp = await editarRequerimientoBE(requeriment, items, files, fileBase, usuario);
                Swal.fire({
                    title: `Se modifico el requerimiento`,
                    icon: 'success',
                    text: `El numero de requerimiento modificado es : ${requeriment.tDocNroFor}`,
                    showConfirmButton: true,
                    allowEnterKey: true
                });
            }else{
                Swal.fire({
                    title: msj,
                    icon: 'warning',
                    showConfirmButton: true,
                    allowEnterKey: true
                });
            }
        }
    }

    /* 
        Manejador encargado de enviar el requerimiento a Softland mediante llamada a rutas del webService.
            Donde data es el requerimiento a enviar.
    */
    const handdleEnviaApi = async (data) =>{
        
        try{
            await enviarReqApi(data);
            console.log(data);
            Swal.fire({
                title: `Enviado`,
                icon: 'success',
                text: `Requerimiento ${data.tDocCodFor}-${data.tDocNroFor} enviado correctamente`,
                showConfirmButton: true,
                allowEnterKey: true
            });
        }catch(err){
            console.log("Error en enviar a Servidor");
            Swal.fire({
                title: err.message,
                icon: 'error',
                showConfirmButton: true,
                allowEnterKey: true
            });
        }
        
    }

    const [listTDocHome, setListTDocHome] = useState((listTDoc) ? listTDoc: []);

    useEffect(() => {
        if(listTDoc) setListTDocHome(filtrar(listTDoc, filtrosEstado));
        
    }, [listTDoc, filtrosEstado, filtrosRango])


    return (
        <div className='container-test'>
            <ReqList currentUser={usuario}
                     userListView={userListView} 
                     listTDoc={listTDocHome} 
                     modificarEstado={handleModifyStateReq} 
                     verDetalle={handlerVerReqDetails} 
                     recuperaFilesDetalle={handlerRecuperaFiles}
                     filtros={{filtrosRango, filtrosEstado}} 
                     aplicarFiltros={apFiltros} 
                     productList={products} 
                     generaPDF={handlerGeneraPDF}
                     checkPermisos={handleCheckPermisos}
                     guardarComo={handleSaveReq}
                     eventoClickEdicion={handdleEditClick}
                     editar={handdleEdit}
                     sendToAPI={handdleEnviaApi}
            />
        </div>
    );
}

export default Home