// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMagnifyingGlass
} from "@fortawesome/free-solid-svg-icons";

// Estilos
import './SearchInput.css'

/* 
    Componente de boton flotante de lupa que funciona para filtrar por busqueda.
        Donde buscar es la función que llama para filtrar por lo ingresado por el usuario.
*/
function SearchInput({ buscar }) {

    return (
        <div className='search-container add-search'>
            <FontAwesomeIcon icon={faMagnifyingGlass} className='add-search-cont' />
            <input placeholder='Buscar...' className='input-search-txt' onChange={data => buscar(data.target.value)} />
        </div>
    );
}

export default SearchInput