//Componentes generales
import React, { useReducer } from "react";
import controlGLBReducer from "./controlGLBReducer";
import controlGLBContext from "./controlGLBContext";
import moment from "moment";

//Axios
import clienteAxios from "../../config/axios";

import { Buffer } from "buffer";

//Rutas de api
import { getProductsByTDocNroFor } from "../../api/product";
import { createPDF, getFiles, updateTDoc, sentToWebService } from "../../api/transactionDocuments";

//Acciones
import {
    BUSCAR_TDOCS,
    MODIFICAR_FILTROS,
    CARGANDO_CONTROL,
    REINICIAR_FILTROS,
    CAMBIAR_FROM,
    SELECCIONAR_TDOC,
    ACTUALIZAR_TDOC,
    AGREGAR_TDOC,
    NOTIFICACION
} from "./controlGLBActions";

/* 
    State encargado de la gestión de filtros y listado de requerimientos.
*/
const ControlGLBState = props => {

    //Valores iniciales
    const initialState = {
        filtros: {
            state: [],
            priority: [0, 1, 2], // 0 media 1 alta 2 urgente
            fecAlt: {
                desde: new Date(moment(new Date).subtract(15, 'days')),
                hasta: new Date(moment(new Date())),
            },
            deliveryDate: new Date(moment(new Date).add(30, 'days')),
            userName: []
        },
        tDocSelected: {},
        notificacion: "",
        from: "Control"
    };

    const [state, dispatch] = useReducer(controlGLBReducer, initialState);



    /* 
        Recupera los productos/items de un requerimiento a partir de su nrofor.
            Donde tDocCodFor es el codfor del requerimiento.
                  tDocNroFor es el nrofor del requerimiento.
    */
    const getProductsByNroFor = async (tDocCodFor,tDocNroFor) => {

        try {
            const resp = await getProductsByTDocNroFor(tDocCodFor, tDocNroFor);

            return resp.data.data
        } catch (error) {
            console.log(error.response);
        }
    }

    /* 
        Encargado de la modificación del estado de autorización.
            Donde data son los datos a modificar.
                  usuario es el usuario en sesión.
                  upd son las modificaciones del dato.
    */
    const modificarEstado = async (data, usuario, upd) => {
        let tDocUpdate = undefined;
        try {
            const resp = await updateTDoc(data, upd, usuario); //clienteAxios.put('/transactionDocument', { id: data._id, user: usuario, updates: upd, fecMod: data.fecMod });
            dispatch({
                type: ACTUALIZAR_TDOC,
                payload: resp.data.data
            })
            tDocUpdate = resp.data;

        } catch (error) {
            console.log(error.response.data.errMsg);
            dispatch({
                type: NOTIFICACION,
                payload: error.response.data.errMsg
            })
        } finally {
            return tDocUpdate;
        }
    }
    
    /* 
        Función encargada de hacer la solicitud al web service de enviar los datos indicados por parametros.
            Donde data es el requerimiento a enviar.
                  usuario es el usuario en sesión.
    */
    const enviarReqApi = async (data, usuario) =>{
        try {
            //await sentToWebService(data._id, usuario);
            const resp = await sentToWebService(data._id, usuario);
            return resp;

        } catch (error) {
            console.log(error.response.data);
            const mensaje = (error.response.data.message !== "") ? error.response.data.message : error.message;
            throw new Error(mensaje);
        } 
    }

    const setFrom = (value) => {
        dispatch({
            type: CAMBIAR_FROM,
            payload: value
        })

    }

    /* 
        Función encargada de abrir en base 64 un String. Utilizada para ver el informe de Requerimiento en pdf.
    */
    function openBase64PDF(base64String) {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const fileUrl = URL.createObjectURL(blob);
        window.open(fileUrl);
    }

    /* 
        Función encargada de generar un pdf del requerimiento.
            Donde tDoc es el requerimiento.
                  usuario es el usuario en sesión.
    */
    const generarInformeReqPDF = async (tDoc, usuario) => {
        try {

            const resp = await createPDF(tDoc.tDocCodFor, tDoc.tDocNroFor, usuario._id);//clienteAxios.post('/transactionDocument/informe', { tDocCodFor: tDoc.tDocCodFor, tDocNroFor: tDoc.tDocNroFor, userId: usuario._id });

            const buff = Buffer.from(resp.data, "base64");
            const base64 = buff.toString("base64");

            openBase64PDF(base64);

        } catch (error) {
            console.log(error);
            console.log("Error en generar informe requerimiento PDF");
        }
    }

    /* 
        Función encargada de recuperar los archivos adjuntados a un requerimiento especifico.
            Donde tDoc es el requerimiento.
    */
    const getFilesByTDoc = async (tDoc) => {
        try {

            const resp = await getFiles(tDoc.tDocCodFor, tDoc.tDocNroFor);

            return resp.data.data;

        } catch (error) {
            console.log(error);
            return [];
        }
    }




    return (
        <controlGLBContext.Provider
            value={{
                filtros: state.filtros,
                tDocSelected: state.tDocSelected,
                notificacion: state.notificacion,
                from: state.from,
                getProductsByNroFor,
                modificarEstado,
                setFrom,
                generarInformeReqPDF,
                getFilesByTDoc,
                enviarReqApi
            }}
        >

            {props.children}

        </controlGLBContext.Provider>
    )

}

export default ControlGLBState;