//Componentes generales
import React, { useState, useEffect } from "react";
import AutocompleteV2 from "../AutoCompleteV2/AutoCompleteV2"

// Estilos
import './NewProductItm.css'

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBackspace
} from "@fortawesome/free-solid-svg-icons";

/*
    Componente encargado de toda la logica del ingreso de un nuevo item en carga de requerimiento.
        Donde data es el item en el cual esta posicionado.
              index es la posición del item.
              productList es la lista de productos disponibles.
              agregaItem es la función que agrega el nuevo item.
              modificaItm es la función que modifica el item.
              borraItem es la función que borra el item.
*/
function NewProductItm({ data, index, productsList, agregaItem, modificaItm, borraItem }) {

    //state de item.
    const [selected, setSelected] = useState(data);

    /* 
        Manejador encargado de la logica del autocompletado ante una modificación.
            Donde value es el valor ingresado por el usuario.
    */
    const handlerChangeAutoComplete = (value) => {
        const indice = index;
        const productoSeleccionado = productsList.find((p) => `${p.tipPro}${p.artCod}`.replace(/\s+/g, '') === value.split(" - ")[0].replace(/\s+/g, ''));
        const s = {...data, tipPro: productoSeleccionado.tipPro, artCod: productoSeleccionado.artCod, codRed: productoSeleccionado.codRed, descrp: productoSeleccionado.descrp};
        
        if(data.artCod !== "") modificaItm(s,indice);
        else agregaItem(s);
        
        setSelected(s); 
    }

    /* 
        Manejador encargado de la modificación de la cantidad del item.
            Donde cantid es el valor ingresado por el usuario.
    */
    const handleChangeQuantity = (cantid) => {
        const indice = index;
        if (cantid > 0) {
            const s = { ...selected, quantity: cantid };
            setSelected(s);
            modificaItm(s,indice);
        }
    }

    /* 
        Manejador encargado de la modificación del texto del item.
            Donde texto es el valor ingresado por el usuario.
    */
    const handleChangeTextAdic = (texto) => {
        const indice = index;
        const s = { ...selected, textAdic: texto };
        setSelected(s);
        modificaItm(s, indice);
    }

    return (
        <div className="container-row-form-item">
            <div>
                <FontAwesomeIcon icon={faBackspace} color="red" style={{ width: '40px', height: '40px', marginTop: "5px", cursor: "pointer" }} onClick={borraItem} />
            </div>

            <div className="input-autocomplete-form-item" style={{ width: "40%", flexGrow: 1 }}>
                <AutocompleteV2 placeholder={"Ingrese un producto"} height={"50px"} value={(selected.tipPro !== "") ? `${selected.tipPro}${selected.artCod} - ${selected.descrp}` : ""} setValue={handlerChangeAutoComplete} dataSuggestions={productsList.map((p) => { return { code: `${p.tipPro}${p.artCod}`, descrp: `${p.descrp} (${p.codRed})` } })} />
            </div>

            <input
                value={selected.quantity === 0 ? 1 : selected.quantity}
                type="number"
                placeholder="Cantidad"
                onChange={(datat) => handleChangeQuantity(datat.target.value)}
                style={{ width: "10%", maxWidth: 60, flexGrow: 1, margin: 4, textAlign: "center", fontWeight: 600, fontSize: 20, borderRadius: 5, border: 'none', boxShadow: 'rgba(148, 142, 142, 0.59) 4px 5px 5px 0px '}}
            />

            <input
                value={selected.textAdic}
                placeholder="Ingrese una descripción adicional"
                onChange={(datat) => handleChangeTextAdic(datat.target.value)}
                style={{  fontSize: '1.3rem', fontWeight: 600, flexGrow: 1, margin: 4, borderRadius: 5, border: 'none', boxShadow: 'rgba(148, 142, 142, 0.59) 4px 5px 5px 0px ' }}
            />

        </div>
    );
}

export default NewProductItm


