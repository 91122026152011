//Componentes generales
import { useContext, useEffect } from 'react';
import {
    Route,
    Routes
} from "react-router-dom";

//Views
import Login from "../../views/login/Login";
import Home from "../../views/home/Home";
import User from "../../views/user/User";
import Product from "../../views/product/Product";
import SharedLayout from "../../components/sharedLayout/SharedLayout";
import AddReq from "../../views/addReq/AddReq";
import Grupos from '../../views/Grupos/Grupos';

//Rutas
import Routes_gral from "../Routes_gral";

import authContext from "../../context/authentication/authContex";

//Token
import tokenAuth from "../../config/token";


// Revisar si tenemos un token
const token = localStorage.getItem('token');

if (token) {
    tokenAuth(token);
}


function Rutas() {

    //Context de Autenticacion   
    const aContext = useContext(authContext);
    const { usuarioAutenticado } = aContext;

    useEffect(() => {
        usuarioAutenticado()
    }, []);

    return (
        <div className="App">
            <Routes>
                <Route path={Routes_gral.login} element={<Login />} />
                <Route path={Routes_gral.group} element={<SharedLayout section={"Grupos"}> <Grupos /> </SharedLayout>} />
                <Route path={Routes_gral.home} element={<SharedLayout section={"Home"}> <Home /> </SharedLayout>} />
                <Route path={Routes_gral.addReq} element={<SharedLayout section={"Nuevo Requerimiento"}> <AddReq edicion={false} /> </SharedLayout>} />
                <Route path={Routes_gral.user} element={<SharedLayout section={"Usuarios"}> <User /> </SharedLayout>} />
                <Route path={Routes_gral.product} element={<SharedLayout section={"Productos"}> <Product /> </SharedLayout>} />
            </Routes>
        </div>
    );
}

export default Rutas;
