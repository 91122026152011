//Componentes generales
import React, { useReducer, useContext, useEffect } from "react";
import controlReducer from "./controlReducer";
import controlContext from "./controlContext";
import moment from "moment";

//Axios
import clienteAxios from "../../config/axios";

//Rutas de api
import { getProductsByTDocNroFor } from "../../api/product";
import { getTDocByFilter, getTDocsByUserIdAndParams } from "../../api/transactionDocuments";

//Socket
import { SocketContext } from "../socket/socket";

//Acciones
import {
    ACTUALIZAR_TDOC,
    AGREGAR_TDOC,
    MODIFICAR_FILTROS_ESTADOS,
    MODIFICAR_FILTROS_RANGO,
    INICIAR_FILTROS
} from "./controlActions";

//Estados de autorización
import { STATES } from "../../utils/states";
import { filtrosToString } from "../../utils/funciones";
import { getUsersInGroupV } from "../../api/user";

//Valores iniciales
const initialState = {
    listTDoc: null,
    filtrosRango: {
        fecAlt: {
            desde: new Date(moment(new Date).subtract(360, 'days')),
            hasta: new Date(moment(new Date())),
        },
        deliveryDate: new Date(moment(new Date).add(30, 'days'))
    },
    filtrosEstado: {
        state: STATES,
        priority: [0, 1, 2],
        userName: []
    },
    filtrosEstadoPredeterminado: null,
    cargando: false,
};

/* 
    State encargado de la gestión de filtros y listado de requerimientos. (No se encuentra tan en uso, en la mayoria fue reemplazado por controlGLB).
*/
const ControlState = props => {

    const server = useContext(SocketContext);
    
    /* 
        Disparadores que sincronizan la lista de requerimientos ante la creación de un requerimiento.
    */
    useEffect(() => {
        if(server){

            //TDoc Nuevo
            server.on('addTDoc', (tDoc) => {
                console.log("RECIBO EL TDOC CREADO")
                dispatch({
                    type: AGREGAR_TDOC,
                    payload: tDoc
                })
            });
    
            return () => server.off('addTDoc');
        }
    }, [server]);

    /* 
        Disparadores que sincronizan la lista de requerimientos ante la modificación de un requerimiento.
    */
    useEffect(() => {
        if(server){
            //TDoc Modificado
            server.on('modTDoc', ({ tDoc }) => {
                dispatch({
                    type: ACTUALIZAR_TDOC,
                    payload: tDoc
                })
            });
    
            return () => server.off('modTDoc');
        }

    },[server])

    const [state, dispatch] = useReducer(controlReducer, initialState);



    //Logica del control state;

    //Inicializa los filtros dependiendo del usuarios 
    const iniciarFiltros = async (states = STATES, usuario = "") => {
        try {
            console.log("Entra en inciar Filtros");
            //Paso todos los dates de los filtros rango a un valor valido ademas de transfor en cadena de string para pasar en peticion get.
            const filtrosString = filtrosToString(initialState.filtrosRango);

            //Relizo peticion con los parametros de rango y el usuario en secion
            const resp = await getTDocsByUserIdAndParams(usuario._id, filtrosString);

            //Obtengo los usuarios que puede ver el usuario mediante su campo groupV
            const respUsuarios = await getUsersInGroupV(usuario._id);
            //console.log(respUsuarios);
            //const usuarios = respUsuarios.data.data.map((u) => u.user.userName);
             //Creo un arreglo de userNames y luego si no se encuentra el usuario en sesion lo agrego
             const usuarios = [...new Set(respUsuarios.data.data.map((u) => u.user.userName))];
             console.log(usuarios);
             if(!usuarios.includes(usuario.userName)) usuarios.push(usuario.userName);

            const filtrosEstado = { ...initialState.filtrosEstado, state: states, userName: usuarios };
            
            dispatch({
                type: INICIAR_FILTROS,
                payload: {
                    tDocs: resp.data.data,
                    filtrosEstado: filtrosEstado
                }
            });

        } catch (error) {
            console.log(error);
            console.log("Error a la hora de iniciar filtros");
        }
    };

    //Modifica los filtros de rango y trae los tDocs acorde a los parametros enviados
    const modificarFiltrosRango = async (userId , filtrosRango) => {

        const filtrosString = filtrosToString(filtrosRango);

        const resp = await getTDocsByUserIdAndParams(userId, filtrosString);//clienteAxios.post('/transactionDocument/filter', filtrosRango);

        const listaRequerimientos = resp.data.data;

        dispatch({
            type: MODIFICAR_FILTROS_RANGO,
            payload: { filtrosRango, listaRequerimientos }
        })
    };

    //Modifica los filtros de estado
    const modificarFiltrosEstado = (filtrosEstados) => {
        dispatch({
            type: MODIFICAR_FILTROS_ESTADOS,
            payload: filtrosEstados
        })
    };

    //Filtra una lista de tDoc de acuerdo a los filtros 
    const filtrar = (lista, filtrosEstado) => {
        if (!lista || lista === undefined || state.filtrosEstado.userName === undefined ) return [];
        
        const listaFiltrada = lista.filter(item => filtrosEstado.state.includes(item.state) && filtrosEstado.priority.includes(item.priority) && (filtrosEstado.userName.includes(item.userName)));
        
        return listaFiltrada;
        
    };


    //Auxiliar
    const getProductsByNroFor = async (tDocCodFor, tDocNroFor) => {

        try {
            const resp = await getProductsByTDocNroFor(tDocCodFor ,tDocNroFor); //clienteAxios.get(`/productBytDocNroFor/${tDocCodFor}/${tDocNroFor}`);
            return resp.data.data
        } catch (error) {
            console.log(error.response);
        }
    };

    return (
        <controlContext.Provider
            value={{
                listTDoc: state.listTDoc,
                cargando: state.cargando,
                filtrosEstado: state.filtrosEstado,
                filtrosRango: state.filtrosRango,
                iniciarFiltros,
                modificarFiltrosEstado,
                modificarFiltrosRango,
                filtrar,
                getProductsByNroFor
            }}
        >

            {props.children}

        </controlContext.Provider>
    )

}

export default ControlState;


    /* 
        Función para aplicar los filtros.
            Donde filtros son los filtros.
    */
/*     const aplicarFiltros = async (filtros) => {

        let list = state.listTDoc;
        //Hay que pasar filtros a date
        try {
            if (
                moment(new Date(state.filtros.fecAlt.desde)).isSame(new Date(filtros.fecAlt.desde), 'day') !== true ||
                moment(new Date(state.filtros.fecAlt.hasta)).isSame(new Date(filtros.fecAlt.hasta), 'day') !== true ||
                moment(new Date(state.filtros.deliveryDate)).isSame(new Date(filtros.deliveryDate), 'day') !== true
            ) {

                filtros.fecAlt.desde = new Date(filtros.fecAlt.desde);
                filtros.fecAlt.hasta = new Date(filtros.fecAlt.hasta);
                filtros.deliveryDate = new Date(filtros.deliveryDate);

                const filtros = { fecAlt: filtros.fecAlt, deliveryDate: filtros.deliveryDate };
                const resp = await  getTDocByFilter(filtros);//clienteAxios.post('/transactionDocument/filter', { fecAlt: filtros.fecAlt, deliveryDate: filtros.deliveryDate });
                list = resp.data.data;

                dispatch({
                    type: APLICAR_FILTROS,
                    payload: {
                        listTDoc: resp.data.data,
                        filtros: filtros
                    }
                });



            } else {

                dispatch({
                    type: APLICAR_FILTROS,
                    payload: {
                        filtros: filtros
                    }
                });

            }

            return { lista: list, filtros: filtros };

        } catch (error) {
            console.log(error);
            console.log("Error a la hora de APLICAR filtros");
        } finally {
            dispatch({
                type: CARGANDO_CONTROL,
                payload: false
            })
        }
    }; */