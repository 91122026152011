//Componentes generales
import { useContext } from 'react';
import StyledNavbar_top from '../navbar/StyledNavbar_top';
import StyledNavbar_bottom from '../navbar/StyledNavbar_bottom';
import Error404 from '../error404/Error404';

//Contexto para validacion de inicio de sesion
import authContext from '../../context/authentication/authContex';

/* 
  Componente encargado de hacer permanentes las barras de navegación top y bottom, lo unico que se actualiza es lo del medio.
      Donde children es el componente que puede cambiar.
            section es el titulo de la sección es que esta posicionado.
*/
const Home = ({children, section}) => {

  //Context auth
  const aContext = useContext(authContext);
  const { usuario } = aContext;

  if(usuario){
    return (
      <div className='sharedLayout'>
        <StyledNavbar_top section={section}/>
        <div>
          {children}
        </div> 
        <StyledNavbar_bottom usuario={usuario} />
      </div>
    );
  }else{
    return (
      <div><Error404 /></div> 
    )
  }
};
export default Home;
