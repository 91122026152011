//Types
import {
    CARGAR_REQ,
    MODIFICAR_REQ,
    SELECCIONAR_PRODUCTO,
    CARGAR_ITEM,
    ELIMINAR_ITEM,
    MODIFICAR_ITEM,
    SELECCIONAR_ITEM,
    CARGAR_PRODUCTOS,
    GUARDAR_REQUERIMIENTO,
    CARGANDO_ADD,
    ERROR_ADD,
    SELECCIONAR_FILE,
    ELIMINAR_FILE,
    ERROR,
    LIMPIAR_ERROR
} from "./agregarReqActions";

const agregarReqReducer = (state, action) => {
    switch (action.type) {

        case CARGAR_REQ: {
            return {
                ...state,
                req: { ...state.req, ...action.payload },
                modReq: false,
                cargando: false,
                error: ""
            }
        }
        case MODIFICAR_REQ: {
            return {
                ...state,
                modReq: action.payload,
                cargando: false,
                error: ""
            }
        }

        case SELECCIONAR_PRODUCTO: {
            return {
                ...state,
                productSelected: action.payload,
                error: ""
            }
        }

        case CARGAR_ITEM: {
            const nuevaLista = [...state.itemsList, action.payload];
            return {
                ...state,
                productSelected: null,
                itemsList: nuevaLista,
                cargando: false,
                error: ""
            }
        }

        case ELIMINAR_ITEM: {
            return {
                ...state,
                itemsList: state.itemsList.filter((item, index) => index !== action.payload),
                error: ""
            }
        }

        case MODIFICAR_ITEM: {
            return {
                ...state,
                itemSelected: null,
                itemsList: state.itemsList.map((item, index) => index === action.payload.index ? action.payload.item : item),
                cargando: false,
                error: ""
            }
        }

        case SELECCIONAR_ITEM: {
            return {
                ...state,
                itemSelected: action.payload
            }
        }

        case CARGAR_PRODUCTOS: {
            return {
                ...state,
                products: action.payload
            }
        }

        case GUARDAR_REQUERIMIENTO: {
            return {
                ...state,
                req: {
                    title: "",
                    observ: "",
                    deliveryDate: new Date(),
                    priority: 0,
                    dateOfIssue: new Date()
                },
                itemsList: [],
                products: [],
                productSelected: null,
                itemSelected: null,
                modReq: false,
                cargando: false,
                error: ""
            }
        }

        case CARGANDO_ADD:
            return {
                ...state,
                cargando: true,
                error: ""
            }

        case ERROR_ADD:
            return {
                ...state,
                cargando: false,
                error: action.payload
            }

        case SELECCIONAR_FILE:
            return {
                ...state,
                files: [...state.files, action.payload]
            }

        case ELIMINAR_FILE:
            return {
                ...state,
                files: state.files.filter((file) => file.name !== action.payload.name),
            }


        case ERROR: {
            return {
                ...state,
                error: action.payload
            }
        }

        case LIMPIAR_ERROR: {
            return {
                ...state,
                error: ""
            }
        }


        default:
            return state;
    }
}

export default agregarReqReducer;