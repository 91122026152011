//Componentes generales
import React, { useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
  
// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmark
} from "@fortawesome/free-solid-svg-icons";

//Tipos de Estado
import { ifState, ifStateBack } from '../../utils/states';

//Estilos
import './StateChange.css';

// Alertas
import Swal from 'sweetalert2'
import { useEffect } from "react";

/* 
    Componente de vista de dialogo de cambio de estado.
        Donde item es el requerimiento a modificar el requerimiento.
              guardarEstado es la función del context que guarda la modificación.
              cerrar es la función encargada de ocultar el dialogo.
*/
function StateChange({ item, guardarEstado, cerrar }) {

    //state del requerimiento.
    const [req, setReq] = useState(item);
    //States para el dropdown
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    //State para check para mostrar estados anteriores
    const [mostrarStatesAnt, setMostrarStatesAnt] = useState(false);
    //State para estados del requerimiento
    const [opciones, setOpciones] = useState(ifState(item.state));


    const handleCheckboxChange = () => {
        const opciones = !mostrarStatesAnt;
        setMostrarStatesAnt(!mostrarStatesAnt);
        setOpciones(opciones ? ifStateBack(item.state) : ifState(item.state));
      };


    /* 
        Función encargada de guardar la modificación del requerimiento y lanzar una alerta.
            Donde r es el requerimiento con el nuevo estado.
    */
    const saveReq = async (r) => {
        const resp = await guardarEstado(r, r.state);
        console.log(resp);
        Swal.fire(
            'Nuevo Estado: ' + r.state,
            'El Estado fue Modificado Correctamente <br> <strong>' + resp.message + ' </strong> ' ,
            'success'
        );
        cerrar(null);
    }

    return (
        <div className='container-gr-state'>
            <div className="label-state-icon-r"><FontAwesomeIcon className="state-icon-r" icon={faXmark} onClick={() => cerrar(null)} /></div>
            <div className='container-state'>
                <div className="label-state-data">
                    {req.tDocCodFor + ' - ' + req.tDocNroFor + ' . Estado Actual: ' +item.state}
                </div>
                <div className="container-state-list">
                    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down" className='dropdown-userTypes'>
                            <DropdownToggle caret>{req.state === '' ? 'Seleccione un estado' : req.state}</DropdownToggle>
                            <DropdownMenu>
                                {opciones.map(function (itemType, index) {
                                    
                                    return (
                                        <article key={itemType}>
                                            <DropdownItem onClick={() => setReq({ ...req, state: itemType})}>{itemType}</DropdownItem>
                                        </article>
                                    );
                                })}
                            </DropdownMenu>
                    </Dropdown>
                <div>
                    <label className='StateChange-div-item-check'>
                        <input type="checkbox" checked={mostrarStatesAnt} onChange={handleCheckboxChange} className='StateChange-list-item-check'></input>
                        <div>Mostrar estados anteriores</div>
                    </label>
                </div>
                </div>
                <div className='btn-state-container'><button className='btn-state' onClick={() => saveReq(req)}>Cambiar Estado</button></div>
            </div>
        </div>
    );
}

export default StateChange