
//Componentes Generales
import React from "react";
import Rutas from "./utils/Rutas/Rutas";

//Contexto
import AuthState from "./context/authentication/authState";
import UsuariosState from "./context/usuarios/usuariosState";
import ProdutosState from "./context/productos/productsState";
import ControlState from "./context/control/controlState";
import ControlGLBState from "./context/controlGLB/controlGLBState";
import AgregarReqState from "./context/agregarReq/agregarReqState";
import GruposState from "./context/grupos/gruposState";

//Socket
import Socket from "./context/socket/socket";

function App() {

  return (
    <div className="App">
      <Socket>
        <AuthState>
          <ControlState>
            <ControlGLBState>
              <AgregarReqState>
                <GruposState>
                  <UsuariosState>
                    <ProdutosState>
                      <Rutas />
                    </ProdutosState>
                  </UsuariosState>
                </GruposState>
              </AgregarReqState>
            </ControlGLBState>
          </ControlState>
        </AuthState>
      </Socket>
    </div>
  );
}

export default App;
