//Componentes generales
import React, { useState, useEffect } from 'react';
import UserInfo from '../../components/UserInfo/UserInfo';
import FormUser from '../FormUser/FormUser';
import SearchInput from "../../components/searchInput/SearchInput";
import AddItm from '../addItm/AddItm';

// Estilos
import './UserList.css';

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUserAlt,
    faPenToSquare,
    faTrash,
    faEye,
    faBracketCurlyRight
} from "@fortawesome/free-solid-svg-icons";

/* 
    Componente encargado de mostrar listado de usuarios.
        Donde list es el listado de usuarios.
              verModificar es la función del context que oculta el dialog de modificación de usuario.
              estadoVerModificar es la función del context que muestra el dialog de modificación de usuario.
              saveModificacion es la función del context encargada de guardar un usuario nuevo o modificado.
              verUser es la función del context que muestra el dialog de detalle de usuario.
              seleccionado es el usuario seleccionado de la lista.
              eliminarUser es la función del context que elimina un usuario (tiene que cumplir ciertas condiciones).
              verficaCantidadUser es la función del context que controla la cantidad de usuarios habilitados contra la cantidad en su licencia.

*/
function UserList({ list = [], verModificar, estadoVerModificar, saveModificacion, verUser, seleccionado, eliminarUser, verificaCantidadUsers, listGroups }) {

    //state para mostrar el dialog de información usuario.
    const [showUserInfo, setShowUserInfo] = useState(false);
    //state titulo del dialog a utilizar.
    const [title, setTitle] = useState('');
    //state del filtro por busqueda.
    const [filter, setFilter] = useState("");
    //state de cantidades de usuarios
    const [controlUsuarios, setControlUsuarios] = useState({userEnabled: 0, maxUsersEnabled: 0, totalUsers: 0 });

    useEffect(() => {
        //Recupera usuarios habilitados y seteo state de control.
        const verificar = async () => {
            const licenciaUsuario = await verificaCantidadUsers();
            setControlUsuarios({...licenciaUsuario.data, totalUsers: list.length});
        }
        verificar();

    }, []);
    
    useEffect(() => {
        setControlUsuarios({...controlUsuarios, totalUsers: list.length});
    }, [list]);

    /* 
        Función que determina si mostrar el detalle del usuario seleccionado.
            Donde usuario es el usuario seleccionado de la lista.
    */
    const checkEstadoVisibilidad = (usuario) => {
        verUser(usuario);
        showUserInfo ? setShowUserInfo(false) : setShowUserInfo(true);
    }

    /* 
        Función que determina si mostrar el formulario para editar el usuario seleccionado.
            Donde user es el usuario seleccionado de la lista.
        Se setea el titulo encabezado del formulario.
    */
    const enviarModificar = (user) =>{
        setTitle('Modificar Usuario');
        verModificar(user);
    }

    /* 
        Función que determina si mostrar el formulario para agregar un nuevo usuario.
            Donde user es el usuario seleccionado (no esta en uso).
        Se setea el titulo encabezado del formulario.
    */
    const enviarAgregar = (user) =>{
        setTitle('Nuevo Usuario');
        verModificar(user);
    }

    /* 
        Función que setea el filtro de busqueda.
            Donde data es lo ingresado por el usuario.
    */
    const handdlerSearchInput = (data) =>{
        setFilter(data);
    }

    return (
        <div>
            <AddItm titulo='usuario' anadiritm={enviarAgregar} />
            <SearchInput buscar={handdlerSearchInput} />
            <div className='container-user-list'>
                <article>
                    <div className='container-list-infoo'>
                            <div className='container-list-item-infoo'>  
                                <div className='cant-user'>
                                    <div className='cant-user-cont1'>
                                        Cantidad de usuarios
                                    </div>
                                    <div className='cant-user-img'>&#123;</div>
                                    <div className='cant-user-cont2'>
                                        <div className='cant-user-items'>Licencia <div className='cant-user-number'>{controlUsuarios.maxUsersEnabled}</div> </div>          
                                        <div className='cant-user-items'>Habilitados  <div className='cant-user-number'>{controlUsuarios.userEnabled}</div> </div>
                                        <div className='cant-user-items'>Deshabilitados  <div className='cant-user-number'>{controlUsuarios.totalUsers - controlUsuarios.userEnabled}</div> </div>     
                                    </div>
                                    <div className='cant-user-img2'>|</div>
                                    <div className='cant-user-cont3'>{controlUsuarios.totalUsers}</div>
                                </div>
                            </div>
                    </div>
                </article>
                {list.map((user) => {
                    if (filter === '' || (user.fullName.toLowerCase().includes(filter.toLowerCase()) || user.userName.toLowerCase().includes(filter.toLowerCase()))){
                    return (
                        <article key={user.userName}>
                            <div className='container-list'>
                                <li>
                                    <div className='container-list-item'>
                                        <div className='list-item-icon'><FontAwesomeIcon icon={faUserAlt} /></div>
                                        <div className='container-list-item-each'>
                                            <div className='label'>Usuario</div>
                                            <div id='item-user' className='list-item-name'>{user.userName}</div>
                                        </div>
                                        <div className='container-list-item-each'>
                                            <div className='label'>Nombre</div>
                                            <div className='list-item'>{user.fullName}</div>
                                        </div>
                                        <div className='container-list-item-each'>
                                            <div className='label'>Email</div>
                                            <div className='list-item'>{user.email}</div>
                                        </div>
                                        <div className='container-list-item-each'>
                                            <div className='label'>Rol</div>
                                            {user.userType.map(function (itemType, index) { 
                                                return (
                                                    <div key={itemType._id} className='list-item-rol'>{itemType.descr}</div>                        
                                                );
                                            })}
                                        </div>
                                        <div className='container-list-item-each'>
                                            <div className='label'>Habilitado</div>
                                            <div><input type="checkbox" checked={user.enabled} className='list-item-check' disabled></input></div>

                                        </div>

                                        <div className='acciones-container'>
                                            <FontAwesomeIcon icon={faPenToSquare} className='icon' onClick={() => enviarModificar(user)} />
                                            <FontAwesomeIcon icon={faTrash} className='icon' onClick={() => eliminarUser(user)} />
                                            <FontAwesomeIcon icon={faEye} className='icon' onClick={() => checkEstadoVisibilidad(user)} />
                                        </div>
                                    </div>
                                </li>
                            </div>
                        </article>
                    );
                    }   
                })}
            </div>
            {showUserInfo &&
                <div className='modalUser'>
                    <UserInfo user={seleccionado} verCerrarSesion={false} mode={checkEstadoVisibilidad} />
                </div>
            }
            {estadoVerModificar &&
                <div className='modalUser'>
                    <FormUser data={seleccionado} minimizar={verModificar} generar={saveModificacion} titulo={title} verificaUser={verificaCantidadUsers} licenciaUsuarios={verificaCantidadUsers} usuariosHabilitados={() => (list.filter((d) => d.enabled)).length } listGroups={listGroups} />
                </div>
            }
        </div>
    );
}

export default UserList