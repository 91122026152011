//Componentes generales
import React, { useState, useEffect } from 'react';
import SearchInput from "../../components/searchInput/SearchInput";
import AddItm from '../addItm/AddItm';

// Estilos
import './GrupoList.css';

//Components
import GrupoItem from '../GrupoItem/GrupoItem';
import FormGroup from '../FormGroup/FormGroup';

import clienteAxios from '../../config/axios';
import { getUsuariosByGroup } from '../../api/group';
import FormNotificacion from '../FormNotificacion/FormNotificacion';
import GrupoInfo from '../GrupoInfo/GrupoInfo';

function GrupoList({ 
    grupos = [], 
    agregarGroup = () => console.log("Agregar un grupo"), 
    modificarGroup = () => console.log("Modificar un grupo"),
    eliminarGroup = () => console.log("Eliminar un grupo"),
    enviarNotificacion = () => console.log("Estoy enviando notificacion")
}) {

    const [verFormAddGroup, setVerFormAddGroup] = useState(false);
    const [verFormNotificacion, setVerFormNotificacion] = useState(false);
    const [verInfoGroup, setVerInfoGroup] = useState(false);
    const [groupSelect, setGroupSelect] = useState();
    const [listaUsuarios, setListaUsuarios] = useState([]);

    const handlerSeleccionarGroup = async (group, typeSelect= "") => {
        
        try{
            const resp = await getUsuariosByGroup(group._id);
            const users = resp.data.data.map((d) => {
                return {value: d.user._id, label: d.user.userName }
            });
           /*  console.log(users); */

            setGroupSelect({group, members: users, typeSelect});

        }catch(err){
            console.log(err);
        }
        
    }

    const handlerCancelarForm = () => {
        setVerFormNotificacion(false)
        setVerFormAddGroup(false);
        setVerInfoGroup(false);
        setGroupSelect(undefined);
    }

    useEffect(() => {
        if(groupSelect !== undefined){ 
            switch(groupSelect.typeSelect) {
                case 'notificacion': 
                    return setVerFormNotificacion(true);
                case 'modificacion':
                    return setVerFormAddGroup(true);
                case 'info':
                    return setVerInfoGroup(true);
                default:
                    return setVerFormAddGroup(true);
            }
        }
    },[groupSelect])

    useEffect(() => {
        return async () => {
            if(!verFormAddGroup && !verInfoGroup){
                try{
                    const resp = await clienteAxios.get(`/user`);
                    setListaUsuarios(resp.data.data.map((u) =>({label: u.userName, value: u._id})));
                }catch(err){
                    setListaUsuarios([]);
                }
            }        
        }
    },[verFormAddGroup, verInfoGroup]);

    return (
        <div>
            <AddItm 
                titulo='grupo'
                anadiritm={() => setVerFormAddGroup(true)}    
            />
            
            <SearchInput />
            <div className='container-group-list'>
                {grupos !== null && grupos.map((g) => {
                    
                    return (
                        <GrupoItem
                            key={g._id}
                            data={g}
                            selectMod={() => handlerSeleccionarGroup(g, "modificacion")}
                            selectNot={() => handlerSeleccionarGroup(g, "notificacion")}
                            selectDelete={() => eliminarGroup(g._id)}
                            selectInfo={() => handlerSeleccionarGroup(g, "info")}
                        />
                    );
                      
                })}
            </div>
            {verFormAddGroup &&
                <div className='modalUser'>
                   <FormGroup
                        titulo={(groupSelect === undefined) ? "Agregar Grupo" : 'Modificar Grupo'}
                        minimizar={handlerCancelarForm}
                        listaUsuarios={listaUsuarios}
                        generar={(groupSelect === undefined) ? agregarGroup : modificarGroup}
                        data={groupSelect}
                   />
                </div>
            }
            {verFormNotificacion &&
                <div className='modalUser'>
                    <FormNotificacion
                        titulo={"Notificacion"}
                        minimizar={handlerCancelarForm}
                        listaUsuarios={listaUsuarios}
                        generar={enviarNotificacion}
                        data={groupSelect}
                    />
             </div>
            }

            {verInfoGroup && 

                <div className='modalUser'>
                    <GrupoInfo
                        data={groupSelect}
                        minimizar={handlerCancelarForm}
                    />
                </div>

            }

        </div>
    );
}

export default GrupoList;