//Componentes generales
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Routes_gral from '../../utils/Routes_gral';

//Estilos
import './UserInfo.css';

//Contexto para cerrar la sesion
import authContext from '../../context/authentication/authContex';

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAddressCard
} from "@fortawesome/free-solid-svg-icons";

/*
    Componente encargaado de mostrar el dialog con el detalle de un usuario.
        Donde user es el usuario seleccionado.
        VerCerrarSesion es un booleano que condiciona la visualización de un boton (reutilización de componente).
        mode es el encargado de cerrar el dialog.
*/
function UserInfo({ user = undefined, verCerrarSesion, mode }) {
    //Context de Autenticacion   
    const aContext = useContext(authContext);
    const { cerrarSesion } = aContext;

    /* 
        Función encargada de cerrar la sesión del usuario y redirigirlo al login.
    */
    const cerrarSesionUsuario = () => {
        cerrarSesion();
        navigate(Routes_gral.login);
    }

    //Inicializo navegacion
    const navigate = useNavigate();

    return (
        <div className='container-gr-profile'>
            <div className='label-profile-icon'><FontAwesomeIcon icon={faAddressCard} /></div>
            <div className='container-profile'>
                <div className='label-profile'>Usuario: <div className='label-profile-data'>{user.userName}</div></div>
                <div className='label-profile'>Email:  <div className='label-profile-data'>{user.email}</div></div>
                <div className='label-profile'>Nombre Completo:  <div className='label-profile-data'>{user.fullName}</div></div>
                <div className='label-profile'>Rol:  
                {user.userType.map(function (itemType, index) { 
                            return (
                                <div key={itemType._id} className='label-profile-data'>{itemType.descr}</div>                        
                            );
                        })}
                </div>
            </div>
            <div className='btn-profile-container'><button className='btn-profile' onClick={mode}>Aceptar</button></div>
            {verCerrarSesion &&
                <div className='btn-profile-container'><button className='btn-profile-cs' onClick={cerrarSesionUsuario}>Cerrar Sesión</button></div>
            }
        </div>
    );
}

export default UserInfo