//Componentes generales
import React, { useState, useEffect } from "react";
import { NavLink } from 'react-router-dom';

//Rutas
import Routes_gral from "../../utils/Routes_gral";

//Permisos
import {VER_USUARIOS, VER_PRODUCTOS, VER_GRUPOS} from "../../utils/permisos";

//Estilos
import './Navbar.css';

//Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faUsersBetweenLines,
  faHouse,
  faBasketShopping,
  faPlusSquare,
  faUsers
} from "@fortawesome/free-solid-svg-icons";

/*
  Componente encarado de las redirecciones de navegación de la pagina.
    Donde usuario es el usuario en sesión.
*/
const StyledNavbar_bottom = ({usuario}) => {
  return (
    <div className=''>
      <div className='navbar-xx'>
        <nav className='navbar-container-xx'>
          <NavLink
            to={Routes_gral.home}
            className='link navbar-item-xx'
          > <FontAwesomeIcon icon={faHouse} className='icon-navbar' /> <div className='navbar-item-label-xx'>Home</div>
          </NavLink>
          <NavLink
            to={Routes_gral.addReq}
            className='link navbar-item-x'
          ><FontAwesomeIcon icon={faPlusSquare} className='icon-navbar' /> <div className='navbar-item-label-xx'>Agregar</div>
          </NavLink>
        { usuario.userType.some(item => VER_USUARIOS.includes(item.typeName)) &&
          <NavLink
            to={Routes_gral.user}
            className='link navbar-item-xx'
          ><FontAwesomeIcon icon={faUsers} className='icon-navbar' /> <div className='navbar-item-label-xx'>Usuarios</div>
          </NavLink>
        }
        { usuario.userType.some(item => VER_GRUPOS.includes(item.typeName)) &&
          <NavLink
            to={Routes_gral.group}
            className='link navbar-item-xx'
          ><FontAwesomeIcon icon={faUsersBetweenLines} className='icon-navbar' /> <div className='navbar-item-label-xx'>Grupos</div>
          </NavLink>
        }
        { usuario.userType.some(item => VER_PRODUCTOS.includes(item.typeName)) &&
          <NavLink
            to={Routes_gral.product}
            className='link navbar-item-xx'
          ><FontAwesomeIcon icon={faBasketShopping} className='icon-navbar' /> <div className='navbar-item-label-xx'>Productos</div>
          </NavLink>
        }
        </nav>
      </div>
    </div>
  );
};
export default StyledNavbar_bottom;