//Componentes generales
import React, { useState, useMemo } from "react";
import { FixedSizeList } from "react-window";
import SearchInput from "../../components/searchInput/SearchInput";
import RenderRow from "../renderRows/RRProducts";

//Estilos
import './ProductList.css'

/*
    Componente encargado de la vista de los productos disponibles.
        Donde productos es la lista de productos disponibles recuperados del context.
*/
function ProductList({ productos = [] }) {

    //state de filtro.
    const [filter, setFilter] = useState("");

    // Calcula el valor del height según el tamaño de la pantalla y el tamaño del navbar
    const listHeight = useMemo(() => window.innerHeight, []);

    /*
        Manejador ante cambios en filtro.
            Donde data es el texto ingresado por el usuario.
    */
    const handdlerSearchInput = (data) => {
        setFilter(data);
    }

    return (
        <div>
            <SearchInput buscar={handdlerSearchInput} />
            <div className='container-product-list'>
                <FixedSizeList
                    height={listHeight}
                    itemCount={ filter === '' ? productos.length : productos.filter(p => p.descrp.toLowerCase().includes(filter.toLowerCase()) || p.codRed.toLowerCase().includes(filter.toLowerCase())).length}
                    itemSize={80}
                    width={"100%"}
                    >
                         {({ index, style }) => (
                            <RenderRow index={index} style={style} productos={productos} filter={filter} />
                        )}
                </FixedSizeList>
            </div>
        </div>

    );
}

export default ProductList