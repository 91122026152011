export function compararArrayOptions(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i].value !== arr2[i].value ) {
            return false;
        }
    }

    return true;
}

export function filtrosToString(filtros){

    let resp = `?`;
    
    if(filtros.fecAlt) resp += `fecAltDesdeDate=${filtros.fecAlt.desde.toISOString()}&fecAltHastaDate=${filtros.fecAlt.hasta.toISOString()}`

    return resp;
}