//Componentes generales
import React, { useState } from "react";

import moment from "moment";

//Permisos
import {EDITAR_REQUERIMIENTO, MODIFICAR_ESTADO_REQUERIMIENTO, VER_TODOS_REQUERIMIENTOS, REENVIO_YARAVI} from "../../utils/permisos";

//Tipos de Prioridades
import { PRIORITYOUT } from '../../utils/priority';

    const RenderRow = ({ currentUser,index, style, listTDoc, filter, checkPermisos, cambiarEstado, editar, verDetalles, statesNEviables, sendToAPI}) => { 

        let currentLista = listTDoc;
        /*
        if(checkPermisos(VER_TODOS_REQUERIMIENTOS)){
            currentLista = listTDoc;
        }else{
            currentLista = listTDoc.filter(r => r.userName.includes(currentUser.userName));
        }*/

        if(currentLista.length > 0){
            if (filter === '') {
                return (
                    <div style={style}>
                        <div className='container-r-list-item'>
                            <div className='container-r-list-item-header'>
                                <div className='r-list-item-header-clave'>{currentLista[index].tDocCodFor + ' - ' + currentLista[index].tDocNroFor}</div>
                                <div className='r-list-item-header-title'>{currentLista[index].title}</div>
                                <div className="margin-right-r">
                                    <div className={currentLista[index].priority === 0 ? 'r-list-item-header-priority-media' : currentLista[index].priority === 1 ? 'r-list-item-header-priority-alta' : 'r-list-item-header-priority-urgente'}>{PRIORITYOUT[currentLista[index].priority]}</div>
                                </div>
                            </div>
                            <div className='container-r-list-item-details'>
                                <div className='r-list-item-details-clave'>{currentLista[index].state}</div>
                                <div className='r-list-item-details-title'>{moment(currentLista[index].fecAlt).format('DD-MM-YYYY HH:MM')}</div>
                                <div className='r-list-item-details-title'>Usuario: {currentLista[index].userName}</div>
                                <div className='r-list-item-details-title'>Items: {currentLista[index].quantity}</div>
                                <div className='r-list-item-details-title'>Fecha de Entrega: {moment(currentLista[index].deliveryDate).format('DD-MM-YYYY')}</div>
                                <div className='r-list-item-details-title'>Ultima modificación: {moment(currentLista[index].fecMod).format('DD-MM-YYYY')}</div>
                            </div>
                            <div className='container-r-list-item-btn'>
                                {checkPermisos(MODIFICAR_ESTADO_REQUERIMIENTO) &&
                                    <div className='r-list-item-btn-A' onClick={() => cambiarEstado(currentLista[index])}>Modificar Estado</div>
                                }
                                {(checkPermisos(EDITAR_REQUERIMIENTO) && currentLista[index].state === 'PENDIENTE') &&
                                    <div className='r-list-item-btn-A' onClick={() => editar(currentLista[index])}>Editar</div>
                                }
                                <div className='r-list-item-btn-A' onClick={() => verDetalles(currentLista[index])}>Detalles</div>
                            </div>
                        {(checkPermisos(REENVIO_YARAVI) && !statesNEviables.includes(currentLista[index].state) && currentLista[index].enviado === false) &&
                            <div className="container-resend" onClick={() => sendToAPI(currentLista[index])}>
                                {"Enviar >>>"}
                            </div>                   
                        }
                        </div>
                    </div>
                );
            } else {
                const req_filters = currentLista.filter(r => r.title.toLowerCase().includes(filter.toLowerCase()) || r.tDocNroFor.toString().toLowerCase().includes(filter.toLowerCase()));
                return (
                    <div style={style}>
                        <div className='container-r-list-item'>
                            <div className='container-r-list-item-header'>
                                <div className='r-list-item-header-clave'>{req_filters[index].tDocCodFor + ' - ' + req_filters[index].tDocNroFor}</div>
                                <div className='r-list-item-header-title'>{req_filters[index].title}</div>
                                <div className={req_filters[index].priority === 0 ? 'r-list-item-header-priority-media' : req_filters[index].priority === 1 ? 'r-list-item-header-priority-alta' : 'r-list-item-header-priority-urgente'}>{PRIORITYOUT[req_filters[index].priority]}</div>
                            </div>
                            <div className='container-r-list-item-details'>
                                <div className='r-list-item-details-clave'>{req_filters[index].state}</div>
                                <div className='r-list-item-details-title'>{moment(req_filters[index].fecAlt).format('DD-MM-YYYY HH:MM')}</div>
                                <div className='r-list-item-details-title'>Usuario: {req_filters[index].userName}</div>
                                <div className='r-list-item-details-title'>Items: {req_filters[index].quantity}</div>
                                <div className='r-list-item-details-title'>Fecha de Entrega: {moment(req_filters[index].deliveryDate).format('DD-MM-YYYY')}</div>
                            </div>
                            <div className='container-r-list-item-btn'>
                                {checkPermisos(MODIFICAR_ESTADO_REQUERIMIENTO) &&
                                    <div className='r-list-item-btn-A' onClick={() => cambiarEstado(req_filters[index])}>Modificar Estado</div>
                                }
                                {(checkPermisos(EDITAR_REQUERIMIENTO) && req_filters[index].state === 'PENDIENTE') &&
                                    <div className='r-list-item-btn-A' onClick={() => editar(req_filters[index])}>Editar</div>
                                }
                                <div className='r-list-item-btn-A' onClick={() => verDetalles(req_filters[index])}>Detalles</div>
                            </div>
                        {(checkPermisos(REENVIO_YARAVI) && !statesNEviables.includes(req_filters[index].state) && req_filters[index].enviado === false) &&
                            <div className="container-resend" onClick={() => sendToAPI(req_filters[index])}>
                                {"Enviar >>>"}
                            </div>                    
                        }
                        </div>
                    </div>
                );
            }
        }
    };

export default RenderRow