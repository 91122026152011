//Componentes generales
import React, { useState, useMemo, useEffect } from "react";
import { FixedSizeList } from "react-window";

//Componentes generales
import SearchInput from '../../components/searchInput/SearchInput';
import StateChange from '../../components/StateChange/StateChange';
import ReqInfo from "../ReqInfo/ReqInfo";
import FilterScreen from "../filterScreen/FilterScreen";
import AddFilter from "../addFilter/AddFilter";
import FormReq from "../FormReq/FormReq";
import RenderRow from "../renderRows/RRReqs";

//Estilos
import './ReqList.css';

//Estados
import { STATES } from '../../utils/states';

/* 
    Componente encargado de mostrar el listado de requerimientos.
        Donde currentUser es el usuario en sesión.
              listTDoc es la lista de requerimientos.
              modificarEstado es la función del context encargarda de realizar una modificación de un campo especifico.
              verDetalle es la función que recupera el detalle de un requerimiento.
              filtros es el filtro donde se aplican las modificaciones por el usuario.
              aplicarFiltros es la función del context que aplica los filtros.
              productList es la lista de productos disponibles.
              generaPDF es la función del context que genera y descarga un archivo pdf.
              checkPermisos es la función del context que chequea si el usuario en sesión tiene permisos.
              guardarComo es la función del context encargada de guardar un requerimiento generado por un "repetir".
              editar es la función del context encargada de guardar un requerimiento generado por un "editar".
              eventoClickEdicion en la función del context que recupera los datos de un requerimiento a editar.
              recuperaFilesDetalle es la función del context que recupera los archivos de un requerimiento.
              sendToAPI es la función del context que envia la solicitud de enviar el requerimiento a Softland.
*/
function ReqList({ 
    currentUser, 
    listTDoc, 
    userListView, 
    modificarEstado, 
    verDetalle, 
    filtros, 
    aplicarFiltros, 
    productList, 
    generaPDF, 
    checkPermisos, 
    guardarComo, 
    editar, 
    eventoClickEdicion, 
    recuperaFilesDetalle, 
    sendToAPI
}) {

    //state para marcar el requerimiento seleccionado.
    const [selected, setSelected] = useState("");
    //state para mostrar dialog de Edición.
    const [selectedEdit, setSelectedEdit] = useState("");
    //state para mostrar dialog de cambio de Estado.
    const [selectedState, setSelectedState] = useState("");
    //state para mostrar dialog de filtro. 
    const [selectedFilter, setSelectedFilter] = useState("");
    //state para mostrar dialog de formulario requerimiento (guardar como).
    const [selectedRepeat, setSelectedRepeat] = useState("");
    //state para filtros.
    const [filter, setFilter] = useState("");
    //state de workspaces seleccionado (Ya definidos: Pendientes y Disponibles).
    const [activeWorkSpace, setActiveWorkSpace] = useState({todos: 1, pendientes: 0, disponibles:0 });
    //state para definir estados no enviables.
    const [stateNoEnviables, setStatesNoEnviable] = useState(["PENDIENTE", "RECHAZADO"]);
    //state para definir tamaño para lista.
    const [itemSize, setItemSize] = useState(300);

    // Calcula el valor del height según el tamaño de la pantalla y el tamaño del navbar
    const listHeight = useMemo(() => window.innerHeight, []);

    /* 
        Manejador que setea el  filtro de busqueda.
            Donde data es lo ingresado por el usuario.
    */
    const handdlerSearchInput = (data) => {
        setFilter(data);
    }

    /* 
        Manejador que abre/cierra dialog de información del requerimiento seleccionado.
            Donde item es el requerimiento seleccionado.
        En este punto, se recuperan los archivos adjuntados al requerimiento.
    */
    const handlerDetails = async (item) => {
        setSelected(null);
        if(item){
            const files = await recuperaFilesDetalle(item);
            setSelected({item, files});
        }
    }

    /* 
        Función encargada de mostrar el dialog de formulario de requerimiento.
            Donde item es el requerimiento del que se hace un guardar como.
                  prods es la lista de productos del requerimiento.
                  agregaItemF es la función de agrega itm (No esta en uso).
        Se setea el estado del requerimiento como pendieente y se agrega al titulo el prefijo "Copia" junto al numero del requerimiento repetido.
    */
    const repeat = async (item, prods, agregaItemF) => {
        setSelectedRepeat(null);
        if(item){
            const {tDocCodFor, tDocNroFor, _id, title, ...rest} = item;
            const nuevaListaP = prods.map((p) => {
                const { _id, ...rest} = p; 
                return rest;
            })
            setSelectedRepeat({item:{...rest, title: `Copia ${tDocCodFor}-${tDocNroFor} ${title}`, state:"PENDIENTE"}, nuevaListaP, agregaItemF});
        }
    }

    /* 
        Manejador que abre/cierra el dialog de formulario en modo guardar como.
            Donde item es el requerimiento.
    */
    const handlerRepeat = async (item) => {
        setSelectedRepeat(null);
        if(item){
            setSelectedRepeat(item);
            guardarComo(item);
        }
    }

    /* 
        Manejador que abre/cierra el dialog de formulario en modo edición.
            Donde req es el requerimiento a editar.
                  agregaItemF es la función de agrega itm (No esta en uso).
        En este punto se recuperan los items y archivos.
    */
    const handlerEdit = async (req, agregaItemF) => {
        setSelectedEdit(null);
        if(req){
            const {items, files} = await eventoClickEdicion(req);
            const {...rest} = req;
            const nuevaListaP = items.map((p) => {
                const {...rest} = p; 
                return rest;
            })
            setSelectedEdit({req:{...rest}, nuevaListaP, agregaItemF, files});
        }
    }

    /* 
        Manejador que abre/cierra el dialog de cambio de estado.
            Donde item es el requerimiento.
    */
    const handlerState = async (item) => {
        setSelectedState(item);
    }

    /* 
        Manejador que abre/cierra el fialog de filtros.
            Donde itm es un booleano que determina si se ve o no.
    */
    const handlerClickFilter = (itm) => {
        setSelectedFilter(itm)
    }

    /* 
        Manejador encargado de abrir en una nueva ventana el archivo seleccionado.
    */
    const handlerAbrirURL = (url) => {
        window.open(url);
    }

    /* 
        Manejador que setea los filtros de estado marcandolos todos si el usuario clickea el workspace "Todos".
    */
    const handleWorkSpaceTodos = () =>{
        setActiveWorkSpace({...activeWorkSpace, todos: 1, pendientes: 0, disponibles: 0});
        const filterEstado = {...filtros.filtrosEstado, state: STATES };
        const filterRango = filtros.filtrosRango;
        aplicarFiltros({filterRango, filterEstado},'');
    }

    /* 
        Manejador que setea los filtros de estado marcandolo solo el estado "Pendientes" si el usuario clickea el workspace "Pendientes".
    */
    const handleWorkSpacePendientes = () =>{
        setActiveWorkSpace({...activeWorkSpace, todos: 0, pendientes: 1, disponibles: 0});
        const estado = ["PENDIENTE"];
        const filterEstado = {...filtros.filtrosEstado, state: estado};
        const filterRango = filtros.filtrosRango;
        aplicarFiltros({filterRango, filterEstado},'');
    }

    /* 
        Manejador que setea los filtros de estado marcandolo solo el estado "Disponibles" si el usuario clickea el workspace "Disponibles".
    */
    const handleWorkSpaceDisponibles = () =>{
        setActiveWorkSpace({...activeWorkSpace, todos: 0, pendientes: 0, disponibles: 1});
        const estado = ["DISPONIBLE"];
        const filterEstado = {...filtros.filtrosEstado, state: estado};
        const filterRango = filtros.filtrosRango;
        aplicarFiltros({filterRango, filterEstado},'');
        
    }

    return (
        <div>
            <div className="navbar-reqList">
                <div className={activeWorkSpace.todos ? "navbar-container-reqList-active" : "navbar-container-reqList"} onClick={handleWorkSpaceTodos} >
                    <div className="navbar-item-reqList">
                        <div className='navbar-item-label-xx' >TODOS</div>
                    </div>
                </div>
                <div className={activeWorkSpace.pendientes ? "navbar-container-reqList-active" : "navbar-container-reqList"} onClick={handleWorkSpacePendientes}>
                    <div className="navbar-item-reqList">
                        <div className='navbar-item-label-xx' >PENDIENTES</div>
                    </div>
                </div>
                <div className={activeWorkSpace.disponibles ? "navbar-container-reqList-active" : "navbar-container-reqList"} onClick={handleWorkSpaceDisponibles}>
                    <div className="navbar-item-reqList">
                        <div className='navbar-item-label-xx' >DISPONIBLES</div>
                    </div>
                </div>
            </div>
            <div className='container-requests-list'>
                <FilterScreen verFiltro={handlerClickFilter} />
                <SearchInput buscar={handdlerSearchInput} />
             
                    <FixedSizeList
                        height={listHeight}
                        style={{flexGrow: 1}}
                        itemCount={filter === '' ? listTDoc.length : listTDoc.filter(r => r.title.toLowerCase().includes(filter.toLowerCase()) || r.tDocNroFor.toString().toLowerCase().includes(filter.toLowerCase())).length}
                        itemSize={itemSize}
                        width={"100%"}
                        
                    >
                        {({ index, style }) => (
                            <RenderRow currentUser={currentUser}  index={index} style={style} listTDoc={listTDoc} filter={filter} checkPermisos={checkPermisos} cambiarEstado={handlerState} editar={handlerEdit} verDetalles={handlerDetails} statesNEviables={stateNoEnviables} sendToAPI={sendToAPI} />
                            )}

                    </FixedSizeList>
            </div>
            {selectedFilter &&
                <div className="modalReq">
                    <AddFilter 
                        usuarios={userListView} 
                        item={selectedFilter} 
                        cerrar={handlerClickFilter} 
                        filtro={filtros} 
                        aplicarFiltros={aplicarFiltros} 
                        workspace={activeWorkSpace} 
                    />
                </div>
            }
            {selected &&
                <div className="modalReq">
                    <ReqInfo item={selected.item} files={selected.files} cerrar={handlerDetails} verDetalle={verDetalle} repetir={repeat} generaPDF={generaPDF} abrirURL={handlerAbrirURL} guardarModificaciones={modificarEstado} usuario={currentUser} />
                </div>
            }            
            {selectedRepeat &&
                <div className="modalReq">
                   <FormReq edicion={"Guardar_Como"}   req={selectedRepeat.item} itemList={selectedRepeat.nuevaListaP} productList={productList} guardarReq={guardarComo} cerrar={handlerRepeat} verDetalle={verDetalle} />
                </div>
            }
            {selectedEdit &&
                <div className="modalReq">
                   <FormReq  edicion={"Edicion"}  req={selectedEdit.req} itemList={selectedEdit.nuevaListaP} files={selectedEdit.files} guardarReq={editar} productList={productList} cerrar={handlerEdit} verDetalle={verDetalle}  />
                </div>
            }
            {selectedState &&
                <div className="modalReq">
                    <StateChange item={selectedState} guardarEstado={(r, state) => modificarEstado(r, { state: state })} cerrar={handlerState} />
                </div>
            }
        </div>

    );
}

export default ReqList